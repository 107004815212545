import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material"
import React, { useCallback, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { OrderBy } from "../../../../api/graphql/graphql-global-types"
import { SmoothCell } from "../../../partials/table/smooth-cell"
import { CollectionGroupOverviewTableContext } from "../context/collection-group-overview-table-context"
import { CollectionGroupOverviewTableRow } from "./collection-group-overview-table-row"

export const CollectionGroupOverviewTable: React.FC = () => {
  const { t } = useTranslation()
  const { collectionGroups, totalResults, error, loading, filter, setPagination } = useContext(
    CollectionGroupOverviewTableContext,
  )

  const handleSort = useCallback(
    (attribute: string) => {
      if (attribute === filter.pagination.orderBy) {
        setPagination({
          ...filter.pagination,
          orderDirection: filter.pagination.orderDirection === OrderBy.ASC ? OrderBy.DESC : OrderBy.ASC,
          page: 0,
        })
      } else {
        setPagination({
          ...filter.pagination,
          orderDirection: OrderBy.ASC,
          orderBy: attribute,
          page: 0,
        })
      }
    },
    [filter, setPagination],
  )

  const hasNoData = useMemo(() => error || (!loading && !totalResults), [error, loading, totalResults])

  return (
    <TableContainer sx={{ border: "none" }}>
      <Table aria-label="collection group table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#F8F9FC", border: "none" }}>
            <SmoothCell sx={{ border: "none" }}>
              <TableSortLabel
                sx={{
                  width: "30%",
                  "& .MuiTableSortLabel-icon": {
                    color: filter.pagination.orderBy !== "label" ? "rgba(0, 0, 0, 0.1)" : undefined,
                  },
                }}
                direction={
                  filter.pagination.orderDirection === OrderBy.ASC || filter.pagination.orderBy !== "label"
                    ? "asc"
                    : "desc"
                }
                onClick={() => handleSort("label")}
              >
                <Typography fontWeight={700}>{t("collection_group_overview.table.name").toUpperCase()}</Typography>
              </TableSortLabel>
            </SmoothCell>
            <SmoothCell sx={{ width: "25%" }}>
              <Typography fontWeight={700}>{t("collection_group_overview.table.district").toUpperCase()}</Typography>
            </SmoothCell>
            <SmoothCell sx={{ width: "25%" }}>
              <Typography fontWeight={700}>{t("collection_group_overview.table.materials").toUpperCase()}</Typography>
            </SmoothCell>
            <SmoothCell sx={{ width: "20%" }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {hasNoData && (
            <TableRow>
              <TableCell colSpan={4} align="center">
                <Typography variant="body2">{t("collection_group_overview.table.no_data")}</Typography>
              </TableCell>
            </TableRow>
          )}
          {loading && (
            <TableRow>
              <TableCell colSpan={4} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}

          {!loading &&
            collectionGroups.map((group) => <CollectionGroupOverviewTableRow key={group.id} group={group} />)}
        </TableBody>
      </Table>
      <TablePagination
        sx={{ borderTop: "1px solid #F2F2F2" }}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalResults}
        rowsPerPage={filter.pagination.pagesize}
        page={filter.pagination.page}
        onPageChange={(_, page) => setPagination({ ...filter.pagination, page })}
        onRowsPerPageChange={(event) =>
          setPagination({ ...filter.pagination, pagesize: parseInt(event.target.value, 10) })
        }
      />
    </TableContainer>
  )
}
