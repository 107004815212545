import { FunctionComponent } from "react";
import { UserRole } from "../api/graphql/graphql-global-types";
import { CollectionGroupOverviewPage } from "../components/pages/collection-groups/collection-group-overview-page";
import { CollectionPointAdministrationPage } from "../components/pages/collection-point-administration/partials/collection-point-administration-page";
import { UploadCollectionPointsPage } from "../components/pages/collection-point-administration/partials/upload/upload-collection-points-page";
import { CollectionPointPage } from "../components/pages/collection-point/collection-point-page";
import { CollectionPointsPage } from "../components/pages/collection-points/collection-points-page";
import { ContainerWarningsPage } from "../components/pages/container-warnings/container-warnings-page";
import { DashboardPage } from "../components/pages/dashboard/dashboard-page";
import { DisposalMerchantOverviewPage } from "../components/pages/disposal-merchant-overview/disposal-merchant-overview-page";
import { GuidanceAdministrationPage } from "../components/pages/guidance-locations-administration/guidance-administration-driver-locations-page";
import { HouseholdContainerPage } from "../components/pages/household-container/household-container-page";
import { HouseholdOverviewPage } from "../components/pages/households/household-overview-page";
import { LoginPage } from "../components/pages/login/login-page";
import { PartnerOverviewPage } from "../components/pages/partner-overview/partner-overview-page";
import { RoutesOverviewPage } from "../components/pages/routes-overview/routes-overview-page";
import { TourGenerationPage } from "../components/pages/tour-generation/tour-generation-page";
import { TourOverviewPage } from "../components/pages/tour-overview/tour-overview-page";
import { UserManagementPage } from "../components/pages/user-management/user-management-page";
import { VehicleOverviewPage } from "../components/pages/vehicle-overview/vehicle-overview-page";
import { UserGroups } from "../models/user-groups";
import { CollectionGroupAddPage } from './../components/pages/collection-groups/collection-group-add-page';
import { CollectionGroupEditPage } from './../components/pages/collection-groups/collection-group-edit-page';
import { RouteDetailsPage } from "./../components/pages/route-details/route-details-page";

export const BASE_PATH = "/portal"
export const PARTNER_PATH = "/partner"
export const ADMINISTRATION_PATH = "/administration"

export interface IPath {
  id: string
  route: string
  component: FunctionComponent
  redirectTo?: string
  roles?: UserRole[]
  children?: IPath[]
}

interface IPathObject {
  [key: string]: IPath
}

export const PATH: IPathObject = {
  LOGIN: {
    id: "login",
    route: `/login`,
    component: LoginPage,
  },
  DASHBOARD: {
    id: "dashboard",
    route: `${BASE_PATH}/dashboard`,
    component: DashboardPage,
    roles: UserGroups.DASHBOARD,
  },
  COLLECTIONPOINTS: {
    id: "collection-points",
    route: `${BASE_PATH}/collection-points`,
    component: CollectionPointsPage,
    roles: UserGroups.COLLECTION_POINTS,
  },
  COLLECTIONPOINT: {
    id: "collectionpoint",
    route: `${BASE_PATH}/collectionpoint/:collectionPointID`,
    component: CollectionPointPage,
    roles: UserGroups.COLLECTION_POINTS,
  },
  TOUR_OVERVIEW: {
    id: "tour-overview",
    route: `${BASE_PATH}/tour-overview`,
    component: TourOverviewPage,
    roles: UserGroups.TOURS,
  },
  TOUR_GENERATION: {
    id: "tour-generation",
    route: `${BASE_PATH}/tour-generation`,
    component: TourGenerationPage,
    roles: UserGroups.TOUR_MANAGEMENT,
  },
  PARTNER_OVERVIEW: {
    id: "partner-overview",
    route: `${BASE_PATH}/partner-overview`,
    component: PartnerOverviewPage,
    roles: UserGroups.PARTNER_MANAGEMENT,
  },
  VEHICLE_OVERVIEW: {
    id: "vehicle-overview",
    route: `${BASE_PATH}${PARTNER_PATH}/:partnerID/vehicle-overview`,
    component: VehicleOverviewPage,
    roles: UserGroups.VEHICLE_MANAGEMENT,
  },
  DISPOSAL_MERCHANT_OVERVIEW: {
    id: "disposal-merchant-overview",
    route: `${BASE_PATH}/disposal-merchant-overview`,
    component: DisposalMerchantOverviewPage,
    roles: UserGroups.DISPOSAL_MERCHANT_MANAGEMENT,
  },
  USER_MANAGEMENT: {
    id: "user-management",
    route: `${BASE_PATH}${ADMINISTRATION_PATH}/users`,
    component: UserManagementPage,
    roles: UserGroups.USER_MANAGEMENT,
  },
  COLLECTIONPOINTS_ADMINISTRATION_UPLOAD: {
    id: "collection_points_administration_upload",
    route: `${BASE_PATH}${ADMINISTRATION_PATH}/collection-points/upload`,
    component: UploadCollectionPointsPage,
    roles: UserGroups.COLLECTION_POINT_MANAGEMENT,
  },
  COLLECTIONPOINTS_ADMINISTRATION_PRE_SELECT: {
    id: "collection_points_administration",
    route: `${BASE_PATH}${ADMINISTRATION_PATH}/collection-points/:collectionPointId`,
    component: CollectionPointAdministrationPage,
    roles: UserGroups.COLLECTION_POINT_MANAGEMENT,
  },
  COLLECTIONPOINTS_ADMINISTRATION: {
    id: "collection_points_administration",
    route: `${BASE_PATH}${ADMINISTRATION_PATH}/collection-points`,
    component: CollectionPointAdministrationPage,
    roles: UserGroups.COLLECTION_POINT_MANAGEMENT,
  },
  ROUTES_OVERVIEW: {
    id: "routes-overview",
    route: `${BASE_PATH}/routes-overview`,
    component: RoutesOverviewPage,
    roles: UserGroups.ROUTES,
  },
  ROUTE_DETAILS: {
    id: "route-details",
    route: `${BASE_PATH}/route-details/:routeId`,
    component: RouteDetailsPage,
    roles: UserGroups.ROUTES,
  },
  GUIDANCELOCATIONS_ADMINISTRATION: {
    id: "guidance_locations_administration",
    route: `${BASE_PATH}/guidance-locations`,
    component: GuidanceAdministrationPage,
    roles: UserGroups.ROUTES,
  },
  CONTAINER_WARNINGS: {
    id: "container-warnings",
    route: `${BASE_PATH}/warnings`,
    component: ContainerWarningsPage,
    roles: UserGroups.CONTAINER_WARNINGS_MANAGEMENT,
  },
  HOUSEHOLD_OVERVIEW: {
    id: "household-overview",
    route: `${BASE_PATH}/households`,
    component: HouseholdOverviewPage,
    roles: UserGroups.HOUSEHOLD_OVERVIEW,
  },
  HOUSEHOLD_DETAILS: {
    id: "household-details",
    route: `${BASE_PATH}/household-details/:householdContainerId`,
    component: HouseholdContainerPage,
    roles: UserGroups.HOUSEHOLD_OVERVIEW,
  },
  COLLECTION_GROUP_OVERVIEW: {
    id: "collection-group-overview",
    route: `${BASE_PATH}/collection-groups`,
    component: CollectionGroupOverviewPage,
    roles: UserGroups.COLLECTION_GROUP_MANAGEMENT,
  },
  COLLECTION_GROUP_ADD_PAGE: {
    id: "collection_group_add_page",
    route: `${BASE_PATH}/collection-groups/add`,
    component: CollectionGroupAddPage,
    roles: UserGroups.COLLECTION_GROUP_MANAGEMENT,
  },
  COLLECTION_GROUP_EDIT_PAGE: {
    id: "collection_group_edit_page",
    route: `${BASE_PATH}/collection-groups/edit/:collectionGroupId`,
    component: CollectionGroupEditPage,
    roles: UserGroups.COLLECTION_GROUP_MANAGEMENT,
  },
}

export const replaceUriParameter = (uri: string, key: string, value: string | number) => {
  return uri.replace(`:${key}`, value.toString())
}
