import { useQuery } from "@apollo/client"
import { Box, Button, CircularProgress, Grid, Stack, Typography } from "@mui/material"
import moment from "moment"
import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  CollectionPointResult,
  GETCOLLECTIONPOINTWITHID_QUERY,
} from "../../../../api/graphql/queries/get-collection-points-with-id"
import { useCollectionGroupWizardContext } from "../context/collection-group-wizard-context"

interface Props {
  id: number
}

export const CollectionGroupCollectionPointInfo: React.FC<Props> = (props) => {
  const { id } = props
  const { t } = useTranslation()
  const { formValues, setFormValues } = useCollectionGroupWizardContext()

  const { loading, data } = useQuery<CollectionPointResult>(GETCOLLECTIONPOINTWITHID_QUERY, {
    variables: {
      id,
    },
  })

  const collectionPoint = data?.getCollectionPointWithID

  const renderRow = useCallback((label: string, value: string) => {
    return (
      <Grid key={`${label}-${value}`} container direction="row">
        <Grid item xs={5}>
          <Typography fontWeight={700}>{label}:</Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography>{value}</Typography>
        </Grid>
      </Grid>
    )
  }, [])

  const isSelected = useMemo(() => (formValues?.collectionPointIds ?? []).includes(id), [formValues, id])

  const handleSelect = useCallback(() => {
    const selectedCps = formValues?.collectionPointIds ?? []
    setFormValues({ ...formValues, collectionPointIds: [...selectedCps, id] })
  }, [formValues, id, setFormValues])

  const handleDeselect = useCallback(() => {
    const selectedCps = formValues?.collectionPointIds ?? []
    setFormValues({ ...formValues, collectionPointIds: selectedCps.filter((cpId) => cpId !== id) })
  }, [formValues, id, setFormValues])

  return (
    <Box sx={{ minWidth: 150 }}>
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <Stack direction="column">
          {renderRow(t("collection_points.table.id"), collectionPoint?.id ?? "-")}
          {renderRow(t("collection_points.table.description"), collectionPoint?.description ?? "-")}
          {renderRow(
            t("collection_points.cards.next_emptying"),
            collectionPoint?.next_emptying ? moment(collectionPoint.next_emptying).format("DD.MM.YYYY") : "-",
          )}
          {renderRow(
            t("collection_points.cards.last_emptying"),
            collectionPoint?.last_emptying ? moment(collectionPoint.last_emptying).format("DD.MM.YYYY") : "-",
          )}
          {renderRow(
            "adresse",
            `${collectionPoint?.street ?? ""}, ${collectionPoint?.cadastral_name ?? ""}, ${collectionPoint?.cadastral_nr ?? ""}`,
          )}
          {(collectionPoint?.collectionPointFilllevels ?? []).map((entry) =>
            renderRow(
              `${entry.material.name} (${entry.count_containers})`,
              `${t("collection_points.table.fill_level")} ${entry.filllevel.toFixed()}%`,
            ),
          )}

          <Button sx={{ mt: 2 }} variant="contained" onClick={isSelected ? handleDeselect : handleSelect}>
            <Typography>{isSelected ? t("remove") : t("add")}</Typography>
          </Button>
        </Stack>
      )}
    </Box>
  )
}
