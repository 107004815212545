import { outlinedInputClasses, Theme, createTheme, alpha } from "@mui/material"
import { DefaultTheme } from "../api/graphql/queries/default-theme"
import { UserTheme } from "../api/graphql/queries/get-me"

interface ITheme {
  PRIMARY_COLOR: IThemeColor
  SECONDARY_COLOR: IThemeColor
  ERROR_COLOR: string
  BACKGROUND_COLOR: string
  TEXT_COLOR: string
  LOGO_PATH: string
}

interface IThemeColor {
  main: string
  light: string
  dark: string
  contrastText: string
}

let theme: ITheme = {} as ITheme
export let LOGO_PATH = ""

export const getTheme = (newTheme: DefaultTheme | UserTheme) => {
  LOGO_PATH = newTheme.logo_url
  theme.BACKGROUND_COLOR = newTheme.portal.background
  theme.ERROR_COLOR = newTheme.portal.error
  theme.LOGO_PATH = newTheme.logo_url
  theme.PRIMARY_COLOR = {
    main: newTheme.portal.primary_main,
    dark: newTheme.portal.primary_dark,
    light: newTheme.portal.primary_light,
    contrastText: newTheme.portal.primary_contrast_text,
  }
  theme.SECONDARY_COLOR = {
    main: newTheme.portal.secondary_main,
    dark: newTheme.portal.secondary_dark,
    light: newTheme.portal.secondary_light,
    contrastText: newTheme.portal.secondary_contrast_text,
  }
  theme.TEXT_COLOR = newTheme.portal.text
  return createAppTheme(theme)
}

export const SPACING = 8
export const DRAWER_WIDTH_OPEN = 220
export const CHART_COLORS = ["#58AF47", "#A4243B", "#275EA5", "#1C0F13", "#FF9F1C"]

export let AppTheme: Theme

const createAppTheme = (newTheme: ITheme) => {
  return createTheme({
    palette: {
      primary: {
        light: newTheme.PRIMARY_COLOR.light,
        main: newTheme.PRIMARY_COLOR.main,
        dark: newTheme.PRIMARY_COLOR.dark,
        contrastText: newTheme.PRIMARY_COLOR.contrastText,
      },
      secondary: {
        light: newTheme.SECONDARY_COLOR.light,
        main: newTheme.SECONDARY_COLOR.main,
        dark: newTheme.SECONDARY_COLOR.dark,
        contrastText: newTheme.SECONDARY_COLOR.contrastText,
      },
      error: {
        main: newTheme.ERROR_COLOR,
      },
    },
    spacing: SPACING,
    typography: {
      fontFamily: "'Roboto', 'Helvetica Neue',Helvetica,Arial,sans-serif",
    },
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: newTheme.SECONDARY_COLOR.main,
            boxShadow: "0 4px 16px 0 rgba(100, 101, 103, 0.25)",
            width: DRAWER_WIDTH_OPEN,
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            "&:hover:not($disabled):not($focused):not($error):before": {
              borderBottom: "1px solid #3b474f",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: 40,
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: newTheme.PRIMARY_COLOR.main,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            '.MuiAutocomplete-option[aria-selected="true"]': {
              backgroundColor: alpha(newTheme.PRIMARY_COLOR.main, 0.2),
              "&.Mui-focused": {
                backgroundColor: alpha(newTheme.PRIMARY_COLOR.main, 0.3),
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderRadius: "4p",
            borderWidth: "1px",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            backgroundColor: alpha(newTheme.BACKGROUND_COLOR, 0.3),
            textAlign: "left",
            minWidth: 100,
            "&:first-child": {
              minWidth: 40,
            },
            "&:last-child": {
              paddingRight: SPACING,
              minWidth: 50,
              borderRight: "none",
            },
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          icon: {
            opacity: 1,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "0",
            boxShadow: "none",
          },
          contained: {
            borderRadius: 2,
            boxShadow: "0 0 12px -5px rgba(0, 0, 0, 0.3)",
            minWidth: 105,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: 14,
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: newTheme.BACKGROUND_COLOR,
            th: {
              fontWeight: "bold",
              color: "gray",
              textTransform: "uppercase",
              textAlign: "left",
            },
          },
        },
      },
    },
  })
}
