import { useMutation, useQuery } from "@apollo/client"
import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react"
import {
  CollectionGroupInput,
  CollectionPointSortingAttribute,
  OrderBy,
  RegionType,
} from "../../../../api/graphql/graphql-global-types"
import { UpsertCollectionGroup } from "../../../../api/graphql/mutations/types/UpsertCollectionGroup"
import { UPSERT_COLLECTION_GROUP_MUTATION } from "../../../../api/graphql/mutations/upsert-collection-group"
import {
  GET_COLLECTIONPOINTS_FOR_MAP_QUERY,
  GetCollectionPointsForMapResult,
  MapCollectionPoint,
} from "../../../../api/graphql/queries/get-collection-points-for-map"
import {
  GET_COLLECTIONPOINT_WITH_REGION_ID_QUERY,
  GetCollectionPointsWithRegionIdPaginationResult,
  GetCollectionPointsWithRegionIDResult,
} from "../../../../api/graphql/queries/get-collection-points-with-region-id"
import { District, DistrictsResult, GET_DISTRICTS_QUERY } from "../../../../api/graphql/queries/get-districts"
import { GETMATERIALS_QUERY, Material, MaterialsResult } from "../../../../api/graphql/queries/get-materials"
import {
  GET_TOWNS_WITH_REGION_ID_QUERY,
  GetTownsWithRegionIDResult,
  Town,
} from "../../../../api/graphql/queries/get-towns-with-region-id"
import { UserContext } from "../../../../context/user-context"
import { CollectionGroupCollectionPointsForm } from "../partials/collection-group-collection-points-form"
import { CollectionGroupGeneralForm } from "../partials/collection-group-general-form"

interface CollectionPointFilters {
  search: string | undefined
  selectedTowns: string[]
  sortByGroupCountAsc: boolean
  page: number
  pageSize: number
}

export interface WizardFormValues {
  districtId?: number
  label?: string
  materialIds?: number[]
  collectionPointIds?: number[]
  selectedTown?: string
}
export enum CollectionGroupWizardStep {
  General = "general",
  CollectionPoints = "collectionPoints",
}

export enum ModeOption {
  Add = "add",
  Edit = "edit",
}

const WizardComponents = {
  [CollectionGroupWizardStep.General]: CollectionGroupGeneralForm,
  [CollectionGroupWizardStep.CollectionPoints]: CollectionGroupCollectionPointsForm,
}

interface CollectionGroupWizardContextProps {
  initialData?: WizardFormValues
  mode: ModeOption
}

interface CollectionGroupWizardContextType {
  currentStep: CollectionGroupWizardStep
  setCurrentStep: (step: CollectionGroupWizardStep) => void
  CurrentComponent: React.FC
  formValues: WizardFormValues | undefined
  setFormValues: (values: WizardFormValues | undefined) => void
  isStepValid: (step: CollectionGroupWizardStep) => boolean
  districts: District[]
  materials: Material[]
  towns: Town[]
  loading: boolean
  cpFilter: CollectionPointFilters
  setCpFilter: (filter: CollectionPointFilters) => void
  collectionPointsForMap: MapCollectionPoint[]
  collectionPointsForTable: GetCollectionPointsWithRegionIdPaginationResult | undefined
  cpForTableLoading: boolean
  selectedCollectionPoint: number | undefined
  setSelectedCollectionPoint: (id: number | undefined) => void
  upsertCollectionGroup: (input: CollectionGroupInput, cpId?: number) => Promise<void>
  mode: ModeOption
}

const WizardContext = createContext<CollectionGroupWizardContextType>({} as CollectionGroupWizardContextType)

const useCollectionGroupWizardContextProvider = ({ initialData, mode }: CollectionGroupWizardContextProps) => {
  const { user } = useContext(UserContext)
  const [currentStep, setCurrentStep] = useState<CollectionGroupWizardStep>(CollectionGroupWizardStep.General)
  const [formValues, setFormValues] = useState<WizardFormValues | undefined>(initialData)
  const [cpFilter, setCpFilter] = useState<CollectionPointFilters>({
    search: undefined,
    sortByGroupCountAsc: false,
    selectedTowns: [],
    page: 0,
    pageSize: 25,
  })
  const [selectedCollectionPoint, setSelectedCollectionPoint] = useState<number | undefined>()

  const CurrentComponent = useMemo(() => WizardComponents[currentStep], [currentStep])

  const [upsertCollectionPointMutation, { loading: upsertLoading }] = useMutation<UpsertCollectionGroup>(
    UPSERT_COLLECTION_GROUP_MUTATION,
  )

  const { data: districtsData, loading: districtsLoading } = useQuery<DistrictsResult>(GET_DISTRICTS_QUERY, {
    skip: !user,
  })

  const { data: materialsData, loading: materialsLoading } = useQuery<MaterialsResult>(GETMATERIALS_QUERY)

  const { data: townsData, loading: townsLoading } = useQuery<GetTownsWithRegionIDResult>(
    GET_TOWNS_WITH_REGION_ID_QUERY,
    {
      variables: { id: formValues?.districtId, type: RegionType.DISTRICT, counties: [] },
      skip: !formValues?.districtId || currentStep !== CollectionGroupWizardStep.CollectionPoints,
    },
  )

  const { data: collectionPointsForMapResult, loading: collectionPointsForMapLoading } =
    useQuery<GetCollectionPointsForMapResult>(GET_COLLECTIONPOINTS_FOR_MAP_QUERY, {
      variables: {
        id: formValues?.districtId,
        page: 0,
        pagesize: 100000,
        type: RegionType.DISTRICT,
        filter: {
          materialIds: formValues?.materialIds,
          towns: cpFilter.selectedTowns ?? undefined,
        },
      },
      skip:
        !formValues?.districtId ||
        !formValues?.materialIds ||
        currentStep !== CollectionGroupWizardStep.CollectionPoints,
    })

  const { data: collectionPointsForTableResult, loading: cpForTableLoading } =
    useQuery<GetCollectionPointsWithRegionIDResult>(GET_COLLECTIONPOINT_WITH_REGION_ID_QUERY, {
      variables: {
        id: formValues?.districtId,
        page: cpFilter.page,
        pagesize: cpFilter.pageSize,
        type: RegionType.DISTRICT,
        filter: {
          searchQuery: cpFilter.search,
          materialIds: formValues?.materialIds,
          towns: cpFilter.selectedTowns ?? undefined,
        },
        order: {
          orderBy: CollectionPointSortingAttribute.COLLECTION_GROUP_COUNT,
          sortingType: cpFilter.sortByGroupCountAsc ? OrderBy.ASC : OrderBy.DESC,
        },
      },
      skip:
        !formValues?.districtId ||
        !formValues?.materialIds ||
        currentStep !== CollectionGroupWizardStep.CollectionPoints,
    })

  const districts = useMemo(() => districtsData?.getDistricts || [], [districtsData])
  const materials = useMemo(() => materialsData?.getMaterials || [], [materialsData])
  const towns = useMemo(() => townsData?.getTownsWithRegionID || [], [townsData])
  const collectionPointsForMap: MapCollectionPoint[] = useMemo(
    () => collectionPointsForMapResult?.getCollectionPointsWithRegionID?.entries ?? [],
    [collectionPointsForMapResult],
  )
  const collectionPointsForTable: GetCollectionPointsWithRegionIdPaginationResult | undefined = useMemo(
    () => collectionPointsForTableResult?.getCollectionPointsWithRegionID,
    [collectionPointsForTableResult],
  )

  useEffect(() => {
    if (!districts || districts.length === 0 || (formValues && formValues.districtId)) return
    const district = districts.sort((a, b) => Number(a.id) - Number(b.id))[0]
    setFormValues({ ...formValues, districtId: Number(district.id) })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districts])

  useEffect(() => {
    if (!collectionPointsForTable || !formValues?.collectionPointIds) return
    const validCollectionPointIds = formValues.collectionPointIds.filter((id) =>
      collectionPointsForTable.entries.some((cp) => Number(cp.id) === id),
    )
    if (validCollectionPointIds.length !== formValues.collectionPointIds.length) {
      setFormValues({ ...formValues, collectionPointIds: validCollectionPointIds })
    }
  }, [collectionPointsForTable, formValues])

  const loading = districtsLoading || materialsLoading || townsLoading || collectionPointsForMapLoading || upsertLoading

  const isStepValid = (step: CollectionGroupWizardStep) => {
    const generalValid =
      formValues?.label !== undefined &&
      formValues?.districtId !== undefined &&
      (formValues?.materialIds?.length ?? 0) > 0
    switch (step) {
      case CollectionGroupWizardStep.General:
        return generalValid
      case CollectionGroupWizardStep.CollectionPoints:
        return generalValid && (formValues?.collectionPointIds?.length ?? 0) > 0
    }
  }

  const upsertCollectionGroup = async (input: CollectionGroupInput, cpId?: number) => {
    await upsertCollectionPointMutation({ variables: { collectionGroupInput: input, collectionGroupId: cpId } })
  }

  return {
    currentStep,
    setCurrentStep,
    CurrentComponent,
    formValues,
    setFormValues,
    districts,
    materials,
    towns,
    loading,
    isStepValid,
    cpFilter,
    setCpFilter,
    collectionPointsForMap,
    collectionPointsForTable,
    cpForTableLoading,
    selectedCollectionPoint,
    setSelectedCollectionPoint,
    upsertCollectionGroup,
    mode,
  }
}

export const CollectionGroupWizardContextProvider: React.FC<PropsWithChildren<CollectionGroupWizardContextProps>> = (
  props,
) => {
  const value = useCollectionGroupWizardContextProvider(props)
  return <WizardContext.Provider value={value}>{props.children}</WizardContext.Provider>
}

export const useCollectionGroupWizardContext = () => useContext(WizardContext)
