import { Card, Grid } from "@mui/material"
import React from "react"
import { CollectionGroupOverviewTableContextProvider } from "./context/collection-group-overview-table-context"
import { CollectionGroupOverviewHeader } from "./partials/collection-group-overview-header"
import { CollectionGroupOverviewTable } from "./partials/collection-group-overview-table"

interface ICollectionGroupOverviewPageProps {}

export const CollectionGroupOverviewPage: React.FC<ICollectionGroupOverviewPageProps> = (_props) => {
  return (
    <CollectionGroupOverviewTableContextProvider>

      <Grid container direction="column" spacing={2} p={2}>
        <Grid item>
          <CollectionGroupOverviewHeader />
        </Grid>
        <Grid item>
          <Card elevation={0} sx={{ borderRadius: 1, px: 2, pt: 2 }}>
              <CollectionGroupOverviewTable />
          </Card>
        </Grid>
      </Grid>

    </CollectionGroupOverviewTableContextProvider>

  )
}
