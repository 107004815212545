import React, { useContext } from "react"
import {
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"
import { ChangeCircle } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { HouseholdContainerContext } from "../context/household-container-context"

interface IHouseholdContainerHistoryProps {}

export const HouseholdContainerHistory: React.FC<IHouseholdContainerHistoryProps> = (props) => {
  const { t } = useTranslation()
  const { histories, historyLoading, totalResults, pagination, setPagination } = useContext(HouseholdContainerContext)

  const containerHistoryEntries = histories?.entries || []
  const historyEntries = containerHistoryEntries.map((entry) => {
    return {
      ...entry,
      date: new Date(entry.date).toLocaleDateString([], { day: "2-digit", month: "2-digit", year: "numeric" }),
      time: new Date(entry.date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }),
      changeType:
        entry.changeType === "update" ? (
          <Stack direction="row" alignItems="center">
            <ChangeCircle sx={{ pr: 1 }} />
            {t("household_details.table.update")}
          </Stack>
        ) : (
          <></>
        ),
      field: t(`household_details.table.field_types.${entry.field}`),
      oldValue:
        entry.field === "comment" && entry.oldValue !== ""
          ? t(`household_details.table.comment_types.${entry.oldValue}`)
          : entry.oldValue,
      newValue:
        entry.field === "comment" && entry.newValue !== ""
          ? t(`household_details.table.comment_types.${entry.newValue}`)
          : entry.newValue,
    }
  })

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>{t("household_details.table.type")}</TableCell>
            <TableCell>{t("household_details.table.date")}</TableCell>
            <TableCell>{t("household_details.table.time")}</TableCell>
            <TableCell>{t("household_details.table.field")}</TableCell>
            <TableCell>{t("household_details.table.old_value")}</TableCell>
            <TableCell>{t("household_details.table.new_value")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {historyLoading && (
            <TableRow>
              <TableCell align="center" sx={{ borderBottom: "none" }}>
                <Skeleton height={50} />
              </TableCell>
              <TableCell align="center" sx={{ borderBottom: "none" }}>
                <Skeleton height={50} />
              </TableCell>
              <TableCell align="center" sx={{ borderBottom: "none" }}>
                <Skeleton height={50} />
              </TableCell>
              <TableCell align="center" sx={{ borderBottom: "none" }}>
                <Skeleton height={50} />
              </TableCell>
              <TableCell align="center" sx={{ borderBottom: "none" }}>
                <Skeleton height={50} />
              </TableCell>
              <TableCell align="center" sx={{ borderBottom: "none" }}>
                <Skeleton height={50} />
              </TableCell>
              <TableCell align="center" sx={{ borderBottom: "none" }}>
                <Skeleton height={50} />
              </TableCell>
            </TableRow>
          )}
          {!historyLoading && historyEntries.length === 0 && (
            <TableRow key={"household_container_history_no_data"}>
              <TableCell colSpan={6} align="center">
                {t("table.no_data")}
              </TableCell>
            </TableRow>
          )}
          {!historyLoading &&
            historyEntries.map((hist, index) => (
              <TableRow key={index}>
                <TableCell>{hist.changeType}</TableCell>
                <TableCell>{hist.date}</TableCell>
                <TableCell>{hist.time}</TableCell>
                <TableCell>{hist.field}</TableCell>
                <TableCell>{hist.oldValue || "-"}</TableCell>
                <TableCell>{hist.newValue || "-"}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={totalResults}
        rowsPerPage={pagination.pageSize}
        page={pagination.page - 1}
        onPageChange={(_, page) => setPagination({ ...pagination, page: page + 1 })}
        onRowsPerPageChange={(event) => setPagination({ ...pagination, pageSize: parseInt(event.target.value) })}
      />
    </TableContainer>
  )
}
