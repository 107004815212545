import { Grid, Skeleton, Stack, useTheme } from "@mui/material"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { CustomMap } from "../../../partials/maps/custom-map"
import { MapsHelper } from "../../../partials/maps/maps-helper"
import { useCollectionGroupWizardContext } from "../context/collection-group-wizard-context"
import { CollectionGroupCollectionPointInfo } from "./collection-group-collection-point-info"
import { CollectionGroupCollectionPointsFilter } from "./collection-group-collection-points-filter"
import { CollectionGroupCollectionPointsTable } from "./collection-group-collection-points-table"

export const CollectionGroupCollectionPointsForm: React.FC = () => {
  const { collectionPointsForMap, selectedCollectionPoint, formValues, loading } = useCollectionGroupWizardContext()

  const theme = useTheme()
  const getMapHeight = useCallback(() => {
    const minHeight = 260
    const indicatorHeight = 100
    const headerHeight = 72
    const buttonWizardRow = 68
    const paddingsOffset = 36
    const height = window.innerHeight - indicatorHeight - headerHeight - buttonWizardRow - paddingsOffset
    return height > minHeight ? height : minHeight
  }, [])

  const [mapHeight, setMapHeight] = useState(getMapHeight())

  useEffect(() => {
    const handleResize = () => {
      setMapHeight(getMapHeight())
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [getMapHeight])

  const markers = useMemo(() => {
    const markers = MapsHelper.getMarkersWithFilllevels(collectionPointsForMap, 100)
    const mapped = markers.map((marker) => {
      const isSelected = formValues?.collectionPointIds?.includes(marker.id)
      const color = isSelected ? theme.palette.primary.main : theme.palette.secondary.light

      return {
        ...marker,
        filllevel: undefined,
        colorOverride: color.replace("#", ""),
      }
    })
    return mapped
  }, [collectionPointsForMap, formValues, theme])

  const renderCustomInfoWindow = (id: number) => {
    return <CollectionGroupCollectionPointInfo id={id} />
  }

  const fitMapBounds = (map: google.maps.Map) => {
    if (markers.length > 1) {
      const bounds = new google.maps.LatLngBounds()

      markers.forEach((marker) => {
        bounds.extend(new google.maps.LatLng(marker.lat, marker.lng))
      })

      map.fitBounds(bounds)
    } else if (markers.length === 1) {
      map.setCenter(new google.maps.LatLng(markers[0].lat, markers[0].lng))
      map.setZoom(15)
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid id="table-grid-item" item xs={8}>
        <Stack direction="column" spacing={2}>
          <CollectionGroupCollectionPointsFilter />
          <CollectionGroupCollectionPointsTable />
        </Stack>
      </Grid>
      <Grid id="map-grid-item" item xs={4}>
        {!loading && (
          <CustomMap
            height={`${mapHeight}px`}
            markers={markers}
            selectedMarkerId={selectedCollectionPoint}
            doRenderCustomInfoWindow
            renderCustomInfoWindow={renderCustomInfoWindow}
            disableFitBounds
            onInit={fitMapBounds}
          />
        )}
        {loading && <Skeleton variant="rectangular" height={600} />}
      </Grid>
    </Grid>
  )
}
